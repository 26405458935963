

#banner { position:relative; display: flex; flex-direction: column; justify-content: center; height: 335px; _background-color: black; padding-top:47px; /* menu / 2 */
  // .banner-bg { will-change:transform }
  > .banner-bg-wrap { position:fixed; top:0; left:0; width:100%; height:335px; will-change:transform }
  > .inner { position:relative; z-index:1; @include inner-pad; width:100%;
    .text { color: white; 
      @include for-ipad-v { 
        br { display:none } 
      } 
    }
  }
  .scroll-down { bottom:-90px }



}

