#template-landing, .landing {
  #main-wrap { 
    .upper { @include inner-pad;
    }
    .subpage-grid-holder { margin-top:80px;
      p { font-size:21px; color: $blue; 
        
      }
      .subpage-grid { display:grid; grid-template-columns:repeat(4, 1fr); gap:40px 16px; margin-top:50px;
        .subpage {
          p { position:relative; font-weight:500; display:inline-block; color:$blue; font-size:14px; margin-top:10px; 
            &:after { content:''; position:absolute; left:0; bottom:0px; width:100%; border-bottom:solid 2px $orange; opacity:0; transform:scaleX(.5); transition:opacity .2s, transform .2s  }
          }
          &:hover p:after { opacity:1; transform:scaleX(1) }
        }
      }
    }
    .text-image-blocks { margin-top:120px; 
      .text-image:nth-of-type(1) { position:relative;
        .image {
          img { width:100% }
        }
        .text { @include abs-fill; @include inner-pad; padding-top:120px; padding-bottom:120px;
          .inner { width:40% }
        }  
      }
      .text-image:nth-of-type(2) { color:white;
        > .inner { @include inner-pad; padding-left:0; padding-top:180px; padding-bottom:180px; position:relative; overflow:hidden; display:flex; flex-direction:row-reverse;  
          .image { width:30%; display:flex; flex-direction:column; justify-content:center;
            img { transform:scale(1.5); transform-origin:right; }
          }
          .text { width:70%; padding-left:100px;
            h2 { color:white }
          }  
        }
      }


      }
    }
    // .text-image {  }
    .text-bullets-image { @include inner-pad; padding-top:120px; padding-bottom:120px;
      .bottom { margin-top:50px; padding-top:50px; border-top:solid 1px $blue-35; display:flex;
        .text { 
          > *:first-child { margin-top:0;
            > *:first-child { margin-top:0; }
          }
        }
        .image { width:40%; flex-shrink:0; margin-left:100px; margin-top:5px } 
      }
  }
}
