.breadcrumb { font-weight:700; font-size:15px; color:$blue; position:relative; z-index:1; _border-top:solid 1px $grey; padding-bottom:17px;
  .arrow-right { margin:0 11px; }
  a { font-weight:400 }
}





.arrow-right { display:inline-block; width: 0; height: 0; border-top: 6px solid transparent; border-bottom: 6px solid transparent; border-left: 6px solid $blue; }
