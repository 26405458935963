// BG-LINK, BUTTONS
a.bg-link { position:relative; display:inline-block; padding:5px 7px; position:relative; left:-7px; color:$blue; 
  span.bg { content:""; display:block; position:absolute; top:0; left:0; width:0; height:100%; background-color:$blue; overflow:hidden; transition:width .2s;
    span.inner-text { display:block; padding:5px 7px; white-space:nowrap; color:white }
  }
  &.button { left:0; padding:10px 15px; border:solid 1px; 
    span.bg { 
      span.inner-text { padding:10px 15px; white-space:nowrap; }
    }
    &.white { color:white;
      span.bg { background-color:white;
        span.inner-text { color:$blue }
      }
    }
  }
  &:hover {
    span.bg { width:100% }
  }
}



a.bg-link.line { border:none; color:black; margin-top:22px; padding:8px 0 6px; left:0;
  &.white { color:white; }

  span.bg { background-color:$orange; width:0; left:-7px; _width:50px;
    span.inner-text { color:white; padding:8px 7px 6px; }
  }
  
  &::after { content:""; position:absolute; bottom:0px; left:0; width:100%; border-bottom:solid 3px $orange;  }
  
  &:hover {
    span.bg { width:calc(100% + 14px) }
  }
  + a.bg-link.line { margin-left:30px }
}



.breadcrumb a.line-link > .inner::after { bottom:1px }
h4 a.line-link > .inner::after { bottom:-3px }
h5 a.line-link > .inner::after { bottom:-2px }