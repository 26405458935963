#template-home {  padding-top:87px;  // menu
  .videos { position:relative; display:flex; width:100%; height:calc(100vh - 86px); padding-bottom:100px;
    .item { position:relative; width:50%; flex-shrink:0; display:flex; flex-direction:column; justify-content:center;
      &:nth-of-type(1) { 
        video { object-position:left }
      }
      .video { @include abs-fill; opacity:0; transition:opacity .5s;
        video { object-fit:cover; }
      }
      &:hover .video { opacity:.1 }
      > .text { @include inner-pad; margin:0;
        h3 { position:relative; color:white; font-size:21px; transition:transform .3s;
          &:after { content:''; position:absolute; left:0; bottom:-18px; width:20px; border-top:solid 1px white }
        }
        h1 { color:white; font-size:42px; line-height:1.2; font-weight:700; margin-top:40px; transition:transform .3s; }
        .links { transition: transform .3s }
      }
      &:hover {
        > .text {
          h3 { transform:translateY(-10px) }
          h1 { transform:translateY(-5px) }
          // .links { transform:translateY(-3px) }
        }
      }
    }
  }
  .text-centered { @include inner-pad; text-align:center; padding-top:100px; padding-bottom:120px; max-width:1000px; margin:auto }
  .image-divider { position:relative; 
    > .inner { @include inner-pad;  position:relative; z-index:1; 
      img { margin:auto; width:100%; max-width:800px }
    }
    .bg { position:absolute; width:100%; height:50%; left:0; bottom:0; background-color:$blue-bg }
  }
  .image-text { background-color:$blue-bg; padding-top:100px; padding-bottom:100px;
    > .inner { @include inner-pad; display:flex; 
      .image { width:45%; flex-shrink:0; margin-left:150px }    
    }
  }


}

