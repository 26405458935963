form {
  input { font-family:$avenir;
    *:first-of-type { margin-top:0 }
		&[type=text], &[type=email], &[type=password], &[type=textarea] { display:block; width:100%; margin-top:18px;  border: solid 1px $grey; font-size:15px; line-height:1.5; padding:10px 10px; }
		&:disabled { cursor:not-allowed !important; opacity:.4 }
	}
	textarea { font-family:$avenir; display:block; width:100%; height:150px; margin-top:18px;  border: solid 1px $grey; font-size:15px; line-height:1.5; padding:10px 10px; }
	.note { padding:15px; margin-top:18px; background-color:$grey;
		p { font-size:14px; line-height:1.5; color:$blue; margin-top:0 }
	}
}
form input[type=submit] { font-family:$avenir;@include smalltext; display:inline-block; border:none; outline:none; cursor:pointer; -webkit-appearance:none; background-color:$orange-lighter; font-size:15px; font-weight:700; color:white; padding:10px 15px; line-height:1.5;
 &.link { background-color:transparent; display:inline; padding:0; text-decoration:underline; color:$blue; font-weight:700;
	&.smaller { font-size:14px; font-weight:400; text-decoration:none }
}
}
.fieldRow { display:flex; justify-content: space-between;
	&:first-child input { margin-top:0 }
	input { width:calc(50% - 10px) !important; }
}

.input-wrap { position:relative; 
	.button, button { position:absolute; top:0; right:0; height:100%;
		&:disabled { cursor:not-allowed !important; opacity:.4 }
	}
}


.thankyou { opacity:0; visibility:hidden; transition:opacity .3s, visibility .3s; _transition-duration:0s; font-size:.85em; margin:0; margin-top:.5em;
	&.show { opacity:1; visibility:visible; transition-duration:.3s }
}




::-webkit-input-placeholder { color:$grey }
::-moz-placeholder { color:$grey }
:-ms-input-placeholder { color:$grey }
::placeholder { color:$grey }




.submit-wrap { position:relative; text-align:right; clear:both; margin-top:25px;
	.loader { position:relative; display:inline-block; width:1.8em; height:1.8em; margin-right:1em; vertical-align:middle; opacity:0; visibility:hidden; transition:opacity .3s, visibility .3s;
		div { width:100%; height:100%;
			div { width:100%; height:100%;
				svg { width:100%; height:100%; }
			}
		}
	}
	&.loading {
		.loader { opacity:.7; visibility: visible }
	}
	&.full { margin-top:1em; position:relative; 
		.loader { position:absolute; right:1em; top:0; bottom:0; margin:auto; }
	}
	input { display:inline-block !important; float:none !important; margin:0 !important; vertical-align:middle;
		&:active { top:0 !important }
	}
	&.footer { position:relative; float:right;
		.loader { position:absolute; top:0; right:5px; width:1.2em; pointer-events:none }	
		input { position:absolute; top:0; right:0 }

	}
}


.close-x { display:block; cursor:pointer; position: absolute; right: 12px; top: 12px; width: 12px; height: 12px; opacity: 1; transition:opacity .3s;
	&:hover { _opacity: .7; }
	&:before, &:after {	position: absolute; left: 5px; content: ' '; height: 12px; width: 2px; background-color:grey; }
	&:before { transform: rotate(45deg); }
	&:after { transform: rotate(-45deg); }
}
