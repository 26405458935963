@font-face {
    font-family: 'merriweather-wf';
    src: url('/fonts/merriweather/merriweather-lightitalic-webfont.woff2') format('woff2'),
         url('/fonts/merriweather/merriweather-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'merriweather-wf';
    src: url('/fonts/merriweather/merriweather-italic-webfont.woff2') format('woff2'),
         url('/fonts/merriweather/merriweather-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'merriweather-wf';
    src: url('/fonts/merriweather/merriweather-bolditalic-webfont.woff2') format('woff2'),
         url('/fonts/merriweather/merriweather-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: 'merriweather-wf';
    src: url('/fonts/merriweather/merriweather-heavyitalic-webfont.woff2') format('woff2'),
         url('/fonts/merriweather/merriweather-heavyitalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;

}

