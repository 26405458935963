* { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

// $poynter: "poynter-oldstyle-display", "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", sans-serif;
$avenir: "avenir-next-wf", sans-serif;
$merriweather: "merriweather-wf", serif;
@mixin smalltext { text-transform:uppercase; letter-spacing:.1em }

body { font-family: $avenir }


h1 { font-weight:400; font-size:28px; line-height:1.67; color:$blue; }
h2 { font-size:24px; font-weight:500; color:$blue }
h3 { font-size:21px; font-weight:500; color:$blue }
h3.italic { font-size:23px; font-family:$merriweather; font-style:italic; }
h4 { @include smalltext; font-weight:500; font-size:12px; color:$blue }
h5 { font-size:16px; font-weight:400; color:$blue }



p { font-weight:400; font-size:16px; line-height:1.875; margin-top:18px; }
p.bigger { font-size:20px; }
.breadcrumb p { font-weight:400; font-size:16px; color:$grey-247;
  .arrow { font-weight:700; color:white; margin:0 10px; }
  .active { font-weight:700; color:white;}
}
ul { font-weight:400; font-size:15px; line-height:1.875; margin-top:18px; padding-left:20px;
  li { margin-top:9px; }
  &.text-only { list-style:none; padding-left:0 }
}


a { text-decoration:none; color:inherit }

p.more { margin-top:20px; 
  a { font-weight:500; font-size: 18px; line-height: 1.4; color: $grey; }
} 



#menu {
  > .loaded-wrap {
    > .inner {
      > ul { 
        > li { @include smalltext; font-size:13px; font-weight:500;
          ul.sub { 
            > li { @include smalltext; font-size:11px; font-weight:700;
              ul.sub-2 { 
                > li { font-size:12px; font-weight:500; font-weight:500; text-transform:none; letter-spacing:0;  }
              }
            }
          }

        }
      }
    }
  }

}




a.bg-link { @include smalltext; font-size:14px; font-weight:700 }


.block .text h4 { margin-top:40px }
.text > *::first-child { margin-top:0 }



::-moz-selection { background-color: $blue-lighter; color:white } 
::selection { background-color: $blue-lighter; color:white }