#template-form {
  #main-wrap {
    .upper { @include inner-pad; }
    .text-image-offset { @include inner-pad; padding-top:60px; padding-bottom:80px;
      > .inner { padding-top:60px; padding-bottom:120px; display:flex; 
        .image { width:30%; flex-shrink:0;
          img { transform:translateX(-100px) scale(1.7); transform-origin:top right }
        }
        .text { width:70%; }
      }
    }
    .form-block { 
      &.has-image { padding-bottom:200px }
      .title { @include inner-pad; 
        .inner { border-top:solid 1px $blue-35; padding:20px 0 30px }
      }
      .bottom { background-color:$blue-bg; padding-top:50px; 
        .inner { @include inner-pad; display:flex; 
          .form { width:60%; flex-shrink:0; padding-bottom:100px; }
          .side { position:relative; width:40%; flex-shrink:0; padding-left:100px; 
            > .image { position:absolute; bottom:-100px }
            .contactInfo { margin-bottom:50px;  
              > div { background-color:$blue-lighter; padding:25px; 
                *:first-child { margin-top:0 }
              }
              p { font-size:15px; color:white; }
              div + div { margin-top:30px}
              .image { display:block; margin-top:25px; }
            }
          }
        }
      }
    }
  
  }
}