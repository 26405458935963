.scroll-down { position:absolute; width:100%; bottom:0; height:100px; display:flex; flex-direction:column; justify-content:center;
  button { position:absolute; left:0; right:0; margin:auto; width:54px; height:54px; border:solid 1px white; border-radius:50%; cursor:pointer; outline:none; background-color:$grey; transition:transform .3s;
    &::before, &::after { content:""; display:block; position:absolute; top:53%; width:17px; border-top:solid 2px white; }
    &::before { right:44.25%; transform:rotate(45deg) }
    &::after { left:44.25%; transform:rotate(-45deg) }  
    &:hover { transform:translateY(-5px) }  
  }  
}



