#template-inner {
  #main-wrap {
    .upper { @include inner-pad; padding-bottom:90px; }
    .text-image { margin-top:60px; display:flex; 
      .image { width:460px; flex-shrink:0; margin-left:100px }
      .text { width:100% }
    }
    .columns-holder { padding:60px 0; margin-bottom:80px;
      > .inner { @include inner-pad; color:white; display:flex; justify-content:space-between;
        .columns-2 { display:flex;
          .column { padding-right:40px; 
            h2 { color:white }
          }
        }
        .image { position:relative; width:330px; flex-shrink:0; margin-left:20px; 
          img { position:absolute; bottom:-60px; transform:translateY(30%) }
        }   
      }
    }
    .slideshow-holder { padding-top:60px; padding-bottom:60px; 
      h1 { text-align:center }
      .slideshow-wrap { position:relative; @include inner-pad; margin-top:50px; 
        .inner { border-top:solid $blue-35 1px; border-bottom:solid $blue-35 1px;
          .slides { display:flex; justify-content:space-between;
            .slide { width:calc(25% - 30px); padding:50px 0 40px;
              h3 { margin-top:5px }
              h5 { margin-top:10px }
              p { border-top:solid $blue-35 1px; margin-top:15px; padding-top:15px; font-size:14px }
              a { margin-top:15px; color:$blue; font-size:12px } 
            }
          }
        }
      }
    }
  }
}



.slideshow-arrow { position:absolute; height:100%; right:0; width:100px; display:flex; flex-direction:row; justify-content:center;
  button { position:absolute; top:0; bottom:38px; margin:auto; width:45px; height:45px; border:solid 1px $blue-lighter; border-radius:50%; cursor:pointer; outline:none; background-color:transparent; transition:transform .3s;
    &::before, &::after { content:""; display:block; position:absolute; left:54%; height:15px; border-left:solid 1px $blue-lighter; }
    &::before { top:44.25%; transform:rotate(45deg) }
    &::after { bottom:44.25%; transform:rotate(-45deg) }  
    &:hover { transform:translateX(5px) }  
  }  
  &.left { right:auto; left:0;
    button {
      &::before, &::after { left:46% }
      &::before { transform:rotate(-45deg) }
      &::after { transform:rotate(45deg) }
    }
  
  }
}