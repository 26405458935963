#template-about {
  #main-wrap { 
    .text-2-columns { display:flex; padding:30px 0 90px;
      .column { width:50%; padding-right:10% }
    }
    .text-image-shaded { display:flex; background-color:$blue-bg; 
      .text { width:50%; display:flex; align-items:center; justify-content:flex-end; @include inner-pad; padding-right:0;
        .inner { padding-left:0; padding-right:20%; max-width:calc(1150px / 2); }
      }
      .image { width:50% }
    }
    .subpage-grid-holder { @include inner-pad; padding-top:50px; padding-bottom:80px;
      h2 { text-align:center }
      .subpage-grid { padding:30px 0 40px; border-top:solid 1px $blue-35; border-bottom:solid 1px $blue-35 }
    }
  }
}
