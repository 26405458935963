#footer { position:relative; z-index:1; background-color: $blue-lighter; color:white;
  .main { padding-top:80px; padding-bottom:80px;
    > .inner { @include inner-pad; display:flex;
      .left { width:35%; padding-right:50px;
        .logo { width:230px; max-width:100% }
        h2 { color:white; margin-top:20px; }
      }
      .mid { width:40%; display:flex }
      .right { width:25% }
      .mid, .right { border-left:solid 1px rgba(255,255,255,.5); padding-left:35px;
        h3 { color:rgba(255,255,255,.8); font-size:18px; }
        li { color:rgba(255,255,255,.6); font-size:15px; }
        a { transition:color .3s;
          &:hover { color:white !important }
        }
        h3:not(*:first-of-type) { margin-top:40px }
      }
      .column + .column { margin-left:45px }
      
    }
  }
  .credit { padding-top:12px; padding-bottom:12px; background-color:rgba(0,0,0,.2);
    > .inner { @include inner-pad; 
      p { margin-top:0; color:rgba(255,255,255,.5); font-size:14px }
    }
  } 
  // @include for-small-desktop { 
  //   .upper { padding-top:60px; padding-bottom:50px;
  //     > .inner { display:flex; flex-direction:column;
  //       .left { 
  //         @include for-ipad-v { padding-right:50px;
  //           br { display:none; }
  //           @include for-phone { padding-right:0 }
  //         }
  //       }
  //       .right { 
  //         .logo { width:100px; float:right }
  //       }
  //     }
  //   }
  //   .lower { padding-top:50px; padding-bottom:50px;
  //     > .inner { 
  //       @include for-phone { display:block; 
  //         .left { display:block;
  //           // .column:first-of-type { flex-shrink:1; min-width:120px; padding-right:20px; }
  //           .column:last-of-type { padding:40px 0 }
  //         }
  //       }
  //     }
  //   }
  // }

}

.grid-10-2 { display:grid; grid-gap:$grid_gap; grid-template-columns:repeat(12, 1fr); 
  grid-template-areas: "left left left left left left left left left left right right";
  .left { grid-area:left;  }
  .right { grid-area:right }
}