$base-delay: 300ms;

.fade-in { opacity:0; transition:opacity .5s ease $base-delay; }
.in-view {
  &.fade-in,
  .fade-in { opacity:1 }
}

.fade-in-up,
.fade-in-left,
.fade-in-right { opacity:0; transition:opacity .5s ease $base-delay, transform .5s ease $base-delay }
.fade-in-up { transform:translateY(5px);  }
.fade-in-left { transform:translateX(15px);  }
.fade-in-right { transform:translateX(-15px);  }

.in-view {
  &.fade-in-up,
  &.fade-in-left,
  &.fade-in-right,
  .fade-in-up,
  .fade-in-left,
  .fade-in-right { opacity:1; transform:none }
}


.in-view-wrap.fade-in { opacity:1; }


.stagger-100 { transition-delay:$base-delay + 100ms }
.stagger-200 { transition-delay:$base-delay + 200ms }
.stagger-300 { transition-delay:$base-delay + 300ms }
.stagger-400 { transition-delay:$base-delay + 400ms }
.stagger-500 { transition-delay:$base-delay + 500ms }
.stagger-600 { transition-delay:$base-delay + 600ms }
.stagger-700 { transition-delay:$base-delay + 700ms }
.stagger-800 { transition-delay:$base-delay + 800ms }
.stagger-900 { transition-delay:$base-delay + 900ms }
.stagger-1000 { transition-delay:$base-delay + 1000ms }
.stagger-1100 { transition-delay:$base-delay + 1100ms }
.stagger-1200 { transition-delay:$base-delay + 1200ms }
.stagger-1300 { transition-delay:$base-delay + 1300ms }
.stagger-1400 { transition-delay:$base-delay + 1400ms }
.stagger-1500 { transition-delay:$base-delay + 1500ms }
.stagger-1600 { transition-delay:$base-delay + 1600ms }
.stagger-1700 { transition-delay:$base-delay + 1700ms }
.stagger-1800 { transition-delay:$base-delay + 1800ms }
.stagger-1900 { transition-delay:$base-delay + 1900ms }
.stagger-2000 { transition-delay:$base-delay + 2000ms }

