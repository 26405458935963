.values-block { 
  &.is-home > .inner { padding-bottom:120px; } 
  > .inner { padding-top:60px;  display:flex; 
    .side { width:35%; flex-shrink:0;
      .text { max-width:300px }
      .image { margin-top:120px; transform:translateX(-125px) }
      
    }
    .main { width:55%; flex-shrink:0; margin-left:10%; display:grid; grid-template-columns:repeat(2, 1fr); gap:60px 45px; margin-top:60px;
      .item { display:flex; 
        .icon { width:50px; flex-shrink:0; height:50px; border-radius:50%; background-color:$blue; margin-right:20px; margin-top:-12px; display:flex; justify-content:center; align-items:center; 
          svg { transform:scale(1.5); }
        }
        .text { 
          p { font-size:15px; line-height:1.75 }
        }
      }
    }  
  }
  &.is-home { @include inner-pad; padding-top:60px; }
}

