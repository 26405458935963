$blue: rgb(24, 66, 143);
$blue-35: rgba(24, 66, 143, .35);
$blue-15: rgba(24, 66, 143, .15);
$blue-lighter: rgb(70, 103, 165);
$blue-bg: rgb(241,244,248);
$orange: rgb(246, 135, 31);
$orange-lighter: rgb(247, 159,75);
$grey-180: rgb(180,180,180);
$grey: rgb(136,136,136);
$yellow: rgb(243,175,35);
$yellow-lighter: rgb(245,191,79);


$grey-247: rgb(247, 247, 247);

#template-home {
  .videos .item:nth-of-type(1) { background-color:$blue-lighter }
  .videos .item:nth-of-type(2) { background-color:$grey }
  .next-steps .steps .step:nth-of-type(1) { background-color:$orange-lighter;
    .bg-link.button .bg .inner-text { color:$orange-lighter }
  }
  .next-steps .steps .step:nth-of-type(2) { background-color:$blue-lighter }
}

.section-job-seekers {
  .text-image-blocks .text-image:nth-of-type(2) { background-color:$orange-lighter }
  #template-inner #main-wrap .columns-holder { background-color:$orange-lighter }
  .next-steps .steps .step:nth-of-type(1) { background-color:$blue-lighter }
  .next-steps .steps .step:nth-of-type(2) { background-color:$orange-lighter;
    .bg-link.button .bg .inner-text { color:$orange-lighter }
  }
}


.section-employers {
  .text-image-blocks .text-image:nth-of-type(2) { background-color:$blue-lighter }
  #template-inner #main-wrap .columns-holder { background-color:$blue-lighter }
  .next-steps .steps .step:nth-of-type(1) { background-color:$blue-lighter }
  .next-steps .steps .step:nth-of-type(2) { background-color:$grey;
    .bg-link.button .bg .inner-text { color:$grey }
  }
}

.section-about {
  .text-image-blocks .text-image:nth-of-type(2) { background-color:$yellow-lighter }
  #template-inner #main-wrap .columns-holder { background-color:$yellow-lighter }
  .next-steps .steps .step:nth-of-type(1) { background-color:$blue-lighter }
  .next-steps .steps .step:nth-of-type(2) { background-color:$yellow-lighter;
    .bg-link.button .bg .inner-text { color:$yellow-lighter }
  }
}
