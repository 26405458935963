@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/64779e28-a3b8-4364-949c-771372a0a327.woff2") format("woff2"),
    url("/fonts/avenir-next/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff") format("woff"),
    url("/fonts/avenir-next/87a872d0-5af3-4231-a77c-37f41ec74662.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2") format("woff2"),
    url("/fonts/avenir-next/f401df6f-54eb-406c-b46a-90b9451c598d.woff") format("woff"),
    url("/fonts/avenir-next/276fe25e-e430-4376-b33c-705dd77b3dba.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),
    url("/fonts/avenir-next/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),
    url("/fonts/avenir-next/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),
    url("/fonts/avenir-next/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"),
    url("/fonts/avenir-next/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),
    url("/fonts/avenir-next/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),
    url("/fonts/avenir-next/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"),
    url("/fonts/avenir-next/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"),
    url("/fonts/avenir-next/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "avenir-next-wf";
  src: 
    url("/fonts/avenir-next/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2") format("woff2"),
    url("/fonts/avenir-next/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff") format("woff"),
    url("/fonts/avenir-next/045d1654-97f2-4ff0-9d24-21ba9dfee219.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
