#template-employers {
  #main-wrap {
    .upper { @include inner-pad;
      .text-image {  padding-top:80px; padding-bottom:80px; display:flex;
        .text {
          *:first-child { margin-top:0 }
        }
        .image { width:45%; flex-shrink:0; margin-left:100px; margin-top:5px }
      }
    }
    .text-image-full { @include inner-pad; 
      > .inner { display:flex; border-top:solid 1px $blue-35; padding:60px 0;
        .image { position:relative; width:33%; flex-shrink:0;
          @include for-small-desktop { 
            img { @include abs-fill; object-fit:cover }
          }
        }
        > .text { padding-left:60px; }   
      }
      &.reverse { 
        > .inner { flex-direction:row-reverse; 
          > .text { padding-left:0; padding-right:60px }
        }
      }
    }
    .next-steps { margin-top:40px }

  }
}