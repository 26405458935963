@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/64779e28-a3b8-4364-949c-771372a0a327.woff2") format("woff2"), url("/fonts/avenir-next/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff") format("woff"), url("/fonts/avenir-next/87a872d0-5af3-4231-a77c-37f41ec74662.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2") format("woff2"), url("/fonts/avenir-next/f401df6f-54eb-406c-b46a-90b9451c598d.woff") format("woff"), url("/fonts/avenir-next/276fe25e-e430-4376-b33c-705dd77b3dba.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"), url("/fonts/avenir-next/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"), url("/fonts/avenir-next/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"), url("/fonts/avenir-next/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"), url("/fonts/avenir-next/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"), url("/fonts/avenir-next/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"), url("/fonts/avenir-next/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"), url("/fonts/avenir-next/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"), url("/fonts/avenir-next/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "avenir-next-wf";
  src: url("/fonts/avenir-next/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2") format("woff2"), url("/fonts/avenir-next/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff") format("woff"), url("/fonts/avenir-next/045d1654-97f2-4ff0-9d24-21ba9dfee219.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'merriweather-wf';
  src: url("/fonts/merriweather/merriweather-lightitalic-webfont.woff2") format("woff2"), url("/fonts/merriweather/merriweather-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'merriweather-wf';
  src: url("/fonts/merriweather/merriweather-italic-webfont.woff2") format("woff2"), url("/fonts/merriweather/merriweather-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'merriweather-wf';
  src: url("/fonts/merriweather/merriweather-bolditalic-webfont.woff2") format("woff2"), url("/fonts/merriweather/merriweather-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'merriweather-wf';
  src: url("/fonts/merriweather/merriweather-heavyitalic-webfont.woff2") format("woff2"), url("/fonts/merriweather/merriweather-heavyitalic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */ }

img {
  display: block;
  max-width: 100%; }

.img-bg-cover {
  background-size: cover;
  background-position: center; }

.img-bg-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.abs-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.fix-fill {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.is-active {
  font-weight: bold; }

iframe {
  width: 600px;
  height: 400px;
  margin-top: 80px; }

#template-home .videos .item:nth-of-type(1) {
  background-color: #4667a5; }

#template-home .videos .item:nth-of-type(2) {
  background-color: #888888; }

#template-home .next-steps .steps .step:nth-of-type(1) {
  background-color: #f79f4b; }
  #template-home .next-steps .steps .step:nth-of-type(1) .bg-link.button .bg .inner-text {
    color: #f79f4b; }

#template-home .next-steps .steps .step:nth-of-type(2) {
  background-color: #4667a5; }

.section-job-seekers .text-image-blocks .text-image:nth-of-type(2) {
  background-color: #f79f4b; }

.section-job-seekers #template-inner #main-wrap .columns-holder {
  background-color: #f79f4b; }

.section-job-seekers .next-steps .steps .step:nth-of-type(1) {
  background-color: #4667a5; }

.section-job-seekers .next-steps .steps .step:nth-of-type(2) {
  background-color: #f79f4b; }
  .section-job-seekers .next-steps .steps .step:nth-of-type(2) .bg-link.button .bg .inner-text {
    color: #f79f4b; }

.section-employers .text-image-blocks .text-image:nth-of-type(2) {
  background-color: #4667a5; }

.section-employers #template-inner #main-wrap .columns-holder {
  background-color: #4667a5; }

.section-employers .next-steps .steps .step:nth-of-type(1) {
  background-color: #4667a5; }

.section-employers .next-steps .steps .step:nth-of-type(2) {
  background-color: #888888; }
  .section-employers .next-steps .steps .step:nth-of-type(2) .bg-link.button .bg .inner-text {
    color: #888888; }

.section-about .text-image-blocks .text-image:nth-of-type(2) {
  background-color: #f5bf4f; }

.section-about #template-inner #main-wrap .columns-holder {
  background-color: #f5bf4f; }

.section-about .next-steps .steps .step:nth-of-type(1) {
  background-color: #4667a5; }

.section-about .next-steps .steps .step:nth-of-type(2) {
  background-color: #f5bf4f; }
  .section-about .next-steps .steps .step:nth-of-type(2) .bg-link.button .bg .inner-text {
    color: #f5bf4f; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: "avenir-next-wf", sans-serif; }

h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 1.67;
  color: #18428f; }

h2 {
  font-size: 24px;
  font-weight: 500;
  color: #18428f; }

h3 {
  font-size: 21px;
  font-weight: 500;
  color: #18428f; }

h3.italic {
  font-size: 23px;
  font-family: "merriweather-wf", serif;
  font-style: italic; }

h4 {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 500;
  font-size: 12px;
  color: #18428f; }

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #18428f; }

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.875;
  margin-top: 18px; }

p.bigger {
  font-size: 20px; }

.breadcrumb p {
  font-weight: 400;
  font-size: 16px;
  color: #f7f7f7; }
  .breadcrumb p .arrow {
    font-weight: 700;
    color: white;
    margin: 0 10px; }
  .breadcrumb p .active {
    font-weight: 700;
    color: white; }

ul {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.875;
  margin-top: 18px;
  padding-left: 20px; }
  ul li {
    margin-top: 9px; }
  ul.text-only {
    list-style: none;
    padding-left: 0; }

a {
  text-decoration: none;
  color: inherit; }

p.more {
  margin-top: 20px; }
  p.more a {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: #888888; }

#menu > .loaded-wrap > .inner > ul > li {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 13px;
  font-weight: 500; }
  #menu > .loaded-wrap > .inner > ul > li ul.sub > li {
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: 11px;
    font-weight: 700; }
    #menu > .loaded-wrap > .inner > ul > li ul.sub > li ul.sub-2 > li {
      font-size: 12px;
      font-weight: 500;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0; }

a.bg-link {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 14px;
  font-weight: 700; }

.block .text h4 {
  margin-top: 40px; }

.text > *::first-child {
  margin-top: 0; }

::selection {
  background-color: #4667a5;
  color: white; }

.transition-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity .3s; }
  .transition-wrap > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .transition-wrap.fade-exit {
    opacity: 0; }

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease 300ms; }

.in-view.fade-in,
.in-view .fade-in {
  opacity: 1; }

.fade-in-up,
.fade-in-left,
.fade-in-right {
  opacity: 0;
  transition: opacity 0.5s ease 300ms, -webkit-transform 0.5s ease 300ms;
  transition: opacity 0.5s ease 300ms, transform 0.5s ease 300ms;
  transition: opacity 0.5s ease 300ms, transform 0.5s ease 300ms, -webkit-transform 0.5s ease 300ms; }

.fade-in-up {
  -webkit-transform: translateY(5px);
          transform: translateY(5px); }

.fade-in-left {
  -webkit-transform: translateX(15px);
          transform: translateX(15px); }

.fade-in-right {
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px); }

.in-view.fade-in-up, .in-view.fade-in-left, .in-view.fade-in-right,
.in-view .fade-in-up,
.in-view .fade-in-left,
.in-view .fade-in-right {
  opacity: 1;
  -webkit-transform: none;
          transform: none; }

.in-view-wrap.fade-in {
  opacity: 1; }

.stagger-100 {
  transition-delay: 400ms; }

.stagger-200 {
  transition-delay: 500ms; }

.stagger-300 {
  transition-delay: 600ms; }

.stagger-400 {
  transition-delay: 700ms; }

.stagger-500 {
  transition-delay: 800ms; }

.stagger-600 {
  transition-delay: 900ms; }

.stagger-700 {
  transition-delay: 1000ms; }

.stagger-800 {
  transition-delay: 1100ms; }

.stagger-900 {
  transition-delay: 1200ms; }

.stagger-1000 {
  transition-delay: 1300ms; }

.stagger-1100 {
  transition-delay: 1400ms; }

.stagger-1200 {
  transition-delay: 1500ms; }

.stagger-1300 {
  transition-delay: 1600ms; }

.stagger-1400 {
  transition-delay: 1700ms; }

.stagger-1500 {
  transition-delay: 1800ms; }

.stagger-1600 {
  transition-delay: 1900ms; }

.stagger-1700 {
  transition-delay: 2000ms; }

.stagger-1800 {
  transition-delay: 2100ms; }

.stagger-1900 {
  transition-delay: 2200ms; }

.stagger-2000 {
  transition-delay: 2300ms; }

.pad-sides > .inner {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1100px) {
    .pad-sides > .inner {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    .pad-sides > .inner {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    .pad-sides > .inner {
      padding-left: 30px;
      padding-right: 30px; } }

#main-wrap {
  position: relative;
  z-index: 1;
  background-color: white;
  padding-top: 90px; }

.center-holder {
  text-align: center; }

.text > *:first-child {
  margin-top: 0; }

.is-active {
  font-weight: bold; }

#menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color .5s, border-color .5s, -webkit-transform .5s;
  transition: transform .5s, background-color .5s, border-color .5s;
  transition: transform .5s, background-color .5s, border-color .5s, -webkit-transform .5s;
  border-bottom: solid 1px transparent; }
  #menu.up {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
    #menu.up ul.sub, #menu.up ul.sub-2 {
      opacity: 0 !important;
      visibility: hidden !important; }
  #menu > .loaded-wrap {
    transition: -webkit-transform .5s ease 1s;
    transition: transform .5s ease 1s;
    transition: transform .5s ease 1s, -webkit-transform .5s ease 1s; }
    #menu > .loaded-wrap.up {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
    #menu > .loaded-wrap > .inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 20px 40px;
      _background-color: white; }
      #menu > .loaded-wrap > .inner .logo {
        width: 160px; }
      #menu > .loaded-wrap > .inner ul {
        list-style: none;
        margin-top: 0;
        padding-left: 0; }
        #menu > .loaded-wrap > .inner ul li {
          display: inline-block;
          margin-top: 0; }
          #menu > .loaded-wrap > .inner ul li a {
            padding: 10px 10px;
            display: block;
            color: #18428f; }
            #menu > .loaded-wrap > .inner ul li a > .inner {
              position: relative; }
              #menu > .loaded-wrap > .inner ul li a > .inner::after {
                content: "";
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 100%;
                border-bottom: solid 3px #f6871f;
                -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                opacity: 0;
                transition: opacity .3s, -webkit-transform .3s;
                transition: transform .3s, opacity .3s;
                transition: transform .3s, opacity .3s, -webkit-transform .3s; }
            #menu > .loaded-wrap > .inner ul li a.active > .inner::after, #menu > .loaded-wrap > .inner ul li a:hover > .inner::after {
              -webkit-transform: scaleX(0.3);
                      transform: scaleX(0.3);
              opacity: 1; }

#menu > .loaded-wrap > .inner > ul > li:last-of-type > a {
  padding-right: 0; }

#menu > .loaded-wrap > .inner > ul {
  display: flex; }
  #menu > .loaded-wrap > .inner > ul > li {
    position: relative;
    margin: 0 10px; }
    #menu > .loaded-wrap > .inner > ul > li ul.sub {
      position: absolute;
      z-index: 1;
      display: block;
      top: 40px;
      left: 0;
      min-width: 100%;
      background-color: white;
      border: solid 1px rgba(0, 0, 0, 0.2);
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s; }
      #menu > .loaded-wrap > .inner > ul > li ul.sub > li {
        position: relative;
        display: block;
        white-space: nowrap; }
        #menu > .loaded-wrap > .inner > ul > li ul.sub > li > a {
          transition: background-color .3s; }
        #menu > .loaded-wrap > .inner > ul > li ul.sub > li:hover > a {
          background-color: rgba(0, 0, 0, 0.05); }
        #menu > .loaded-wrap > .inner > ul > li ul.sub > li ul.sub-2 {
          position: absolute;
          display: block;
          top: -1px;
          left: 100%;
          background-color: white;
          border: solid 1px rgba(0, 0, 0, 0.2);
          opacity: 0;
          visibility: hidden;
          transition: opacity .3s, visibility .3s; }
          #menu > .loaded-wrap > .inner > ul > li ul.sub > li ul.sub-2 > li {
            display: block; }
            #menu > .loaded-wrap > .inner > ul > li ul.sub > li ul.sub-2 > li > a {
              transition: background-color .3s; }
            #menu > .loaded-wrap > .inner > ul > li ul.sub > li ul.sub-2 > li:hover > a {
              background-color: rgba(0, 0, 0, 0.05); }
        #menu > .loaded-wrap > .inner > ul > li ul.sub > li:hover ul.sub-2 {
          opacity: 1;
          visibility: visible;
          transition: opacity .3s, visibility .3s; }
    #menu > .loaded-wrap > .inner > ul > li:hover ul.sub {
      opacity: 1;
      visibility: visible;
      transition: opacity .3s, visibility .3s; }

body.menu-has-bg #menu {
  background-color: white;
  border-bottom-color: rgba(24, 66, 143, 0.15); }

#footer {
  position: relative;
  z-index: 1;
  background-color: #4667a5;
  color: white; }
  #footer .main {
    padding-top: 80px;
    padding-bottom: 80px; }
    #footer .main > .inner {
      padding-left: 125px;
      padding-right: 125px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      display: flex; }
      @media (max-width: 1100px) {
        #footer .main > .inner {
          padding-left: 100px;
          padding-right: 100px; } }
      @media (max-width: 768px) {
        #footer .main > .inner {
          padding-left: 50px;
          padding-right: 50px; } }
      @media (max-width: 600px) {
        #footer .main > .inner {
          padding-left: 30px;
          padding-right: 30px; } }
      #footer .main > .inner .left {
        width: 35%;
        padding-right: 50px; }
        #footer .main > .inner .left .logo {
          width: 230px;
          max-width: 100%; }
        #footer .main > .inner .left h2 {
          color: white;
          margin-top: 20px; }
      #footer .main > .inner .mid {
        width: 40%;
        display: flex; }
      #footer .main > .inner .right {
        width: 25%; }
      #footer .main > .inner .mid, #footer .main > .inner .right {
        border-left: solid 1px rgba(255, 255, 255, 0.5);
        padding-left: 35px; }
        #footer .main > .inner .mid h3, #footer .main > .inner .right h3 {
          color: rgba(255, 255, 255, 0.8);
          font-size: 18px; }
        #footer .main > .inner .mid li, #footer .main > .inner .right li {
          color: rgba(255, 255, 255, 0.6);
          font-size: 15px; }
        #footer .main > .inner .mid a, #footer .main > .inner .right a {
          transition: color .3s; }
          #footer .main > .inner .mid a:hover, #footer .main > .inner .right a:hover {
            color: white !important; }
        #footer .main > .inner .mid h3:not(*:first-of-type), #footer .main > .inner .right h3:not(*:first-of-type) {
          margin-top: 40px; }
      #footer .main > .inner .column + .column {
        margin-left: 45px; }
  #footer .credit {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: rgba(0, 0, 0, 0.2); }
    #footer .credit > .inner {
      padding-left: 125px;
      padding-right: 125px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto; }
      @media (max-width: 1100px) {
        #footer .credit > .inner {
          padding-left: 100px;
          padding-right: 100px; } }
      @media (max-width: 768px) {
        #footer .credit > .inner {
          padding-left: 50px;
          padding-right: 50px; } }
      @media (max-width: 600px) {
        #footer .credit > .inner {
          padding-left: 30px;
          padding-right: 30px; } }
      #footer .credit > .inner p {
        margin-top: 0;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px; }

.grid-10-2 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "left left left left left left left left left left right right"; }
  .grid-10-2 .left {
    grid-area: left; }
  .grid-10-2 .right {
    grid-area: right; }

#banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 335px;
  _background-color: black;
  padding-top: 47px;
  /* menu / 2 */ }
  #banner > .banner-bg-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 335px;
    will-change: transform; }
  #banner > .inner {
    position: relative;
    z-index: 1;
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
    @media (max-width: 1100px) {
      #banner > .inner {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      #banner > .inner {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      #banner > .inner {
        padding-left: 30px;
        padding-right: 30px; } }
    #banner > .inner .text {
      color: white; }
      @media (max-width: 768px) {
        #banner > .inner .text br {
          display: none; } }
  #banner .scroll-down {
    bottom: -90px; }

.breadcrumb {
  font-weight: 700;
  font-size: 15px;
  color: #18428f;
  position: relative;
  z-index: 1;
  _border-top: solid 1px #888888;
  padding-bottom: 17px; }
  .breadcrumb .arrow-right {
    margin: 0 11px; }
  .breadcrumb a {
    font-weight: 400; }

.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #18428f; }

.scroll-down {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .scroll-down button {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 54px;
    height: 54px;
    border: solid 1px white;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    background-color: #888888;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s; }
    .scroll-down button::before, .scroll-down button::after {
      content: "";
      display: block;
      position: absolute;
      top: 53%;
      width: 17px;
      border-top: solid 2px white; }
    .scroll-down button::before {
      right: 44.25%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .scroll-down button::after {
      left: 44.25%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .scroll-down button:hover {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px); }

a.bg-link {
  position: relative;
  display: inline-block;
  padding: 5px 7px;
  position: relative;
  left: -7px;
  color: #18428f; }
  a.bg-link span.bg {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #18428f;
    overflow: hidden;
    transition: width .2s; }
    a.bg-link span.bg span.inner-text {
      display: block;
      padding: 5px 7px;
      white-space: nowrap;
      color: white; }
  a.bg-link.button {
    left: 0;
    padding: 10px 15px;
    border: solid 1px; }
    a.bg-link.button span.bg span.inner-text {
      padding: 10px 15px;
      white-space: nowrap; }
    a.bg-link.button.white {
      color: white; }
      a.bg-link.button.white span.bg {
        background-color: white; }
        a.bg-link.button.white span.bg span.inner-text {
          color: #18428f; }
  a.bg-link:hover span.bg {
    width: 100%; }

a.bg-link.line {
  border: none;
  color: black;
  margin-top: 22px;
  padding: 8px 0 6px;
  left: 0; }
  a.bg-link.line.white {
    color: white; }
  a.bg-link.line span.bg {
    background-color: #f6871f;
    width: 0;
    left: -7px;
    _width: 50px; }
    a.bg-link.line span.bg span.inner-text {
      color: white;
      padding: 8px 7px 6px; }
  a.bg-link.line::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-bottom: solid 3px #f6871f; }
  a.bg-link.line:hover span.bg {
    width: calc(100% + 14px); }
  a.bg-link.line + a.bg-link.line {
    margin-left: 30px; }

.breadcrumb a.line-link > .inner::after {
  bottom: 1px; }

h4 a.line-link > .inner::after {
  bottom: -3px; }

h5 a.line-link > .inner::after {
  bottom: -2px; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  _background-color: black;
  opacity: .3;
  background-image: url("/images/ui/loader.svg");
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 1s, visibility 1s; }
  .loader.first-load {
    z-index: 11; }
    .loader.first-load.loaded {
      opacity: 0;
      visibility: hidden; }

.next-steps {
  padding-top: 60px;
  padding-bottom: 60px; }
  .next-steps h3 {
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    @media (max-width: 1100px) {
      .next-steps h3 {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      .next-steps h3 {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      .next-steps h3 {
        padding-left: 30px;
        padding-right: 30px; } }
  .next-steps .steps {
    margin-top: 40px;
    display: flex; }
    .next-steps .steps .step {
      width: 50%;
      flex-shrink: 0;
      padding: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .next-steps .steps .step h2 {
        font-size: 26px;
        color: white; }

.title-block {
  padding: 60px 0;
  border-top: solid rgba(24, 66, 143, 0.35) 1px;
  border-bottom: solid rgba(24, 66, 143, 0.35) 1px;
  padding-right: 100px; }
  .title-block h1 {
    max-width: 900px; }

.values-block.is-home > .inner {
  padding-bottom: 120px; }

.values-block > .inner {
  padding-top: 60px;
  display: flex; }
  .values-block > .inner .side {
    width: 35%;
    flex-shrink: 0; }
    .values-block > .inner .side .text {
      max-width: 300px; }
    .values-block > .inner .side .image {
      margin-top: 120px;
      -webkit-transform: translateX(-125px);
              transform: translateX(-125px); }
  .values-block > .inner .main {
    width: 55%;
    flex-shrink: 0;
    margin-left: 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 45px;
    gap: 60px 45px;
    margin-top: 60px; }
    .values-block > .inner .main .item {
      display: flex; }
      .values-block > .inner .main .item .icon {
        width: 50px;
        flex-shrink: 0;
        height: 50px;
        border-radius: 50%;
        background-color: #18428f;
        margin-right: 20px;
        margin-top: -12px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .values-block > .inner .main .item .icon svg {
          -webkit-transform: scale(1.5);
                  transform: scale(1.5); }
      .values-block > .inner .main .item .text p {
        font-size: 15px;
        line-height: 1.75; }

.values-block.is-home {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px; }
  @media (max-width: 1100px) {
    .values-block.is-home {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    .values-block.is-home {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    .values-block.is-home {
      padding-left: 30px;
      padding-right: 30px; } }

#template-inner #main-wrap .upper {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 90px; }
  @media (max-width: 1100px) {
    #template-inner #main-wrap .upper {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-inner #main-wrap .upper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-inner #main-wrap .upper {
      padding-left: 30px;
      padding-right: 30px; } }

#template-inner #main-wrap .text-image {
  margin-top: 60px;
  display: flex; }
  #template-inner #main-wrap .text-image .image {
    width: 460px;
    flex-shrink: 0;
    margin-left: 100px; }
  #template-inner #main-wrap .text-image .text {
    width: 100%; }

#template-inner #main-wrap .columns-holder {
  padding: 60px 0;
  margin-bottom: 80px; }
  #template-inner #main-wrap .columns-holder > .inner {
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1100px) {
      #template-inner #main-wrap .columns-holder > .inner {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      #template-inner #main-wrap .columns-holder > .inner {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      #template-inner #main-wrap .columns-holder > .inner {
        padding-left: 30px;
        padding-right: 30px; } }
    #template-inner #main-wrap .columns-holder > .inner .columns-2 {
      display: flex; }
      #template-inner #main-wrap .columns-holder > .inner .columns-2 .column {
        padding-right: 40px; }
        #template-inner #main-wrap .columns-holder > .inner .columns-2 .column h2 {
          color: white; }
    #template-inner #main-wrap .columns-holder > .inner .image {
      position: relative;
      width: 330px;
      flex-shrink: 0;
      margin-left: 20px; }
      #template-inner #main-wrap .columns-holder > .inner .image img {
        position: absolute;
        bottom: -60px;
        -webkit-transform: translateY(30%);
                transform: translateY(30%); }

#template-inner #main-wrap .slideshow-holder {
  padding-top: 60px;
  padding-bottom: 60px; }
  #template-inner #main-wrap .slideshow-holder h1 {
    text-align: center; }
  #template-inner #main-wrap .slideshow-holder .slideshow-wrap {
    position: relative;
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px; }
    @media (max-width: 1100px) {
      #template-inner #main-wrap .slideshow-holder .slideshow-wrap {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      #template-inner #main-wrap .slideshow-holder .slideshow-wrap {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      #template-inner #main-wrap .slideshow-holder .slideshow-wrap {
        padding-left: 30px;
        padding-right: 30px; } }
    #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner {
      border-top: solid rgba(24, 66, 143, 0.35) 1px;
      border-bottom: solid rgba(24, 66, 143, 0.35) 1px; }
      #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner .slides {
        display: flex;
        justify-content: space-between; }
        #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner .slides .slide {
          width: calc(25% - 30px);
          padding: 50px 0 40px; }
          #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner .slides .slide h3 {
            margin-top: 5px; }
          #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner .slides .slide h5 {
            margin-top: 10px; }
          #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner .slides .slide p {
            border-top: solid rgba(24, 66, 143, 0.35) 1px;
            margin-top: 15px;
            padding-top: 15px;
            font-size: 14px; }
          #template-inner #main-wrap .slideshow-holder .slideshow-wrap .inner .slides .slide a {
            margin-top: 15px;
            color: #18428f;
            font-size: 12px; }

.slideshow-arrow {
  position: absolute;
  height: 100%;
  right: 0;
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .slideshow-arrow button {
    position: absolute;
    top: 0;
    bottom: 38px;
    margin: auto;
    width: 45px;
    height: 45px;
    border: solid 1px #4667a5;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s; }
    .slideshow-arrow button::before, .slideshow-arrow button::after {
      content: "";
      display: block;
      position: absolute;
      left: 54%;
      height: 15px;
      border-left: solid 1px #4667a5; }
    .slideshow-arrow button::before {
      top: 44.25%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .slideshow-arrow button::after {
      bottom: 44.25%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .slideshow-arrow button:hover {
      -webkit-transform: translateX(5px);
              transform: translateX(5px); }
  .slideshow-arrow.left {
    right: auto;
    left: 0; }
    .slideshow-arrow.left button::before, .slideshow-arrow.left button::after {
      left: 46%; }
    .slideshow-arrow.left button::before {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .slideshow-arrow.left button::after {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }

#template-landing #main-wrap .upper, .landing #main-wrap .upper {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1100px) {
    #template-landing #main-wrap .upper, .landing #main-wrap .upper {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-landing #main-wrap .upper, .landing #main-wrap .upper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-landing #main-wrap .upper, .landing #main-wrap .upper {
      padding-left: 30px;
      padding-right: 30px; } }

#template-landing #main-wrap .subpage-grid-holder, .landing #main-wrap .subpage-grid-holder {
  margin-top: 80px; }
  #template-landing #main-wrap .subpage-grid-holder p, .landing #main-wrap .subpage-grid-holder p {
    font-size: 21px;
    color: #18428f; }
  #template-landing #main-wrap .subpage-grid-holder .subpage-grid, .landing #main-wrap .subpage-grid-holder .subpage-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px 16px;
    gap: 40px 16px;
    margin-top: 50px; }
    #template-landing #main-wrap .subpage-grid-holder .subpage-grid .subpage p, .landing #main-wrap .subpage-grid-holder .subpage-grid .subpage p {
      position: relative;
      font-weight: 500;
      display: inline-block;
      color: #18428f;
      font-size: 14px;
      margin-top: 10px; }
      #template-landing #main-wrap .subpage-grid-holder .subpage-grid .subpage p:after, .landing #main-wrap .subpage-grid-holder .subpage-grid .subpage p:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        border-bottom: solid 2px #f6871f;
        opacity: 0;
        -webkit-transform: scaleX(0.5);
                transform: scaleX(0.5);
        transition: opacity .2s, -webkit-transform .2s;
        transition: opacity .2s, transform .2s;
        transition: opacity .2s, transform .2s, -webkit-transform .2s; }
    #template-landing #main-wrap .subpage-grid-holder .subpage-grid .subpage:hover p:after, .landing #main-wrap .subpage-grid-holder .subpage-grid .subpage:hover p:after {
      opacity: 1;
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }

#template-landing #main-wrap .text-image-blocks, .landing #main-wrap .text-image-blocks {
  margin-top: 120px; }
  #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1), .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) {
    position: relative; }
    #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .image img, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .image img {
      width: 100%; }
    #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 125px;
      padding-right: 125px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 120px;
      padding-bottom: 120px; }
      @media (max-width: 1100px) {
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text {
          padding-left: 100px;
          padding-right: 100px; } }
      @media (max-width: 768px) {
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text {
          padding-left: 50px;
          padding-right: 50px; } }
      @media (max-width: 600px) {
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text {
          padding-left: 30px;
          padding-right: 30px; } }
      #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text .inner, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(1) .text .inner {
        width: 40%; }
  #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2), .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) {
    color: white; }
    #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner {
      padding-left: 125px;
      padding-right: 125px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-top: 180px;
      padding-bottom: 180px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse; }
      @media (max-width: 1100px) {
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner {
          padding-left: 100px;
          padding-right: 100px; } }
      @media (max-width: 768px) {
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner {
          padding-left: 50px;
          padding-right: 50px; } }
      @media (max-width: 600px) {
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner {
          padding-left: 30px;
          padding-right: 30px; } }
      #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .image, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .image {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .image img, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .image img {
          -webkit-transform: scale(1.5);
                  transform: scale(1.5);
          -webkit-transform-origin: right;
                  transform-origin: right; }
      #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .text, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .text {
        width: 70%;
        padding-left: 100px; }
        #template-landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .text h2, .landing #main-wrap .text-image-blocks .text-image:nth-of-type(2) > .inner .text h2 {
          color: white; }

#template-landing .text-bullets-image, .landing .text-bullets-image {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-bottom: 120px; }
  @media (max-width: 1100px) {
    #template-landing .text-bullets-image, .landing .text-bullets-image {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-landing .text-bullets-image, .landing .text-bullets-image {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-landing .text-bullets-image, .landing .text-bullets-image {
      padding-left: 30px;
      padding-right: 30px; } }
  #template-landing .text-bullets-image .bottom, .landing .text-bullets-image .bottom {
    margin-top: 50px;
    padding-top: 50px;
    border-top: solid 1px rgba(24, 66, 143, 0.35);
    display: flex; }
    #template-landing .text-bullets-image .bottom .text > *:first-child, .landing .text-bullets-image .bottom .text > *:first-child {
      margin-top: 0; }
      #template-landing .text-bullets-image .bottom .text > *:first-child > *:first-child, .landing .text-bullets-image .bottom .text > *:first-child > *:first-child {
        margin-top: 0; }
    #template-landing .text-bullets-image .bottom .image, .landing .text-bullets-image .bottom .image {
      width: 40%;
      flex-shrink: 0;
      margin-left: 100px;
      margin-top: 5px; }

#template-about #main-wrap .text-2-columns {
  display: flex;
  padding: 30px 0 90px; }
  #template-about #main-wrap .text-2-columns .column {
    width: 50%;
    padding-right: 10%; }

#template-about #main-wrap .text-image-shaded {
  display: flex;
  background-color: #f1f4f8; }
  #template-about #main-wrap .text-image-shaded .text {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0; }
    @media (max-width: 1100px) {
      #template-about #main-wrap .text-image-shaded .text {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      #template-about #main-wrap .text-image-shaded .text {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      #template-about #main-wrap .text-image-shaded .text {
        padding-left: 30px;
        padding-right: 30px; } }
    #template-about #main-wrap .text-image-shaded .text .inner {
      padding-left: 0;
      padding-right: 20%;
      max-width: calc(1150px / 2); }
  #template-about #main-wrap .text-image-shaded .image {
    width: 50%; }

#template-about #main-wrap .subpage-grid-holder {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 80px; }
  @media (max-width: 1100px) {
    #template-about #main-wrap .subpage-grid-holder {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-about #main-wrap .subpage-grid-holder {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-about #main-wrap .subpage-grid-holder {
      padding-left: 30px;
      padding-right: 30px; } }
  #template-about #main-wrap .subpage-grid-holder h2 {
    text-align: center; }
  #template-about #main-wrap .subpage-grid-holder .subpage-grid {
    padding: 30px 0 40px;
    border-top: solid 1px rgba(24, 66, 143, 0.35);
    border-bottom: solid 1px rgba(24, 66, 143, 0.35); }

#template-form #main-wrap .upper {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1100px) {
    #template-form #main-wrap .upper {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-form #main-wrap .upper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-form #main-wrap .upper {
      padding-left: 30px;
      padding-right: 30px; } }

#template-form #main-wrap .text-image-offset {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 80px; }
  @media (max-width: 1100px) {
    #template-form #main-wrap .text-image-offset {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-form #main-wrap .text-image-offset {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-form #main-wrap .text-image-offset {
      padding-left: 30px;
      padding-right: 30px; } }
  #template-form #main-wrap .text-image-offset > .inner {
    padding-top: 60px;
    padding-bottom: 120px;
    display: flex; }
    #template-form #main-wrap .text-image-offset > .inner .image {
      width: 30%;
      flex-shrink: 0; }
      #template-form #main-wrap .text-image-offset > .inner .image img {
        -webkit-transform: translateX(-100px) scale(1.7);
                transform: translateX(-100px) scale(1.7);
        -webkit-transform-origin: top right;
                transform-origin: top right; }
    #template-form #main-wrap .text-image-offset > .inner .text {
      width: 70%; }

#template-form #main-wrap .form-block.has-image {
  padding-bottom: 200px; }

#template-form #main-wrap .form-block .title {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1100px) {
    #template-form #main-wrap .form-block .title {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-form #main-wrap .form-block .title {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-form #main-wrap .form-block .title {
      padding-left: 30px;
      padding-right: 30px; } }
  #template-form #main-wrap .form-block .title .inner {
    border-top: solid 1px rgba(24, 66, 143, 0.35);
    padding: 20px 0 30px; }

#template-form #main-wrap .form-block .bottom {
  background-color: #f1f4f8;
  padding-top: 50px; }
  #template-form #main-wrap .form-block .bottom .inner {
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    display: flex; }
    @media (max-width: 1100px) {
      #template-form #main-wrap .form-block .bottom .inner {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      #template-form #main-wrap .form-block .bottom .inner {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      #template-form #main-wrap .form-block .bottom .inner {
        padding-left: 30px;
        padding-right: 30px; } }
    #template-form #main-wrap .form-block .bottom .inner .form {
      width: 60%;
      flex-shrink: 0;
      padding-bottom: 100px; }
    #template-form #main-wrap .form-block .bottom .inner .side {
      position: relative;
      width: 40%;
      flex-shrink: 0;
      padding-left: 100px; }
      #template-form #main-wrap .form-block .bottom .inner .side > .image {
        position: absolute;
        bottom: -100px; }
      #template-form #main-wrap .form-block .bottom .inner .side .contactInfo {
        margin-bottom: 50px; }
        #template-form #main-wrap .form-block .bottom .inner .side .contactInfo > div {
          background-color: #4667a5;
          padding: 25px; }
          #template-form #main-wrap .form-block .bottom .inner .side .contactInfo > div *:first-child {
            margin-top: 0; }
        #template-form #main-wrap .form-block .bottom .inner .side .contactInfo p {
          font-size: 15px;
          color: white; }
        #template-form #main-wrap .form-block .bottom .inner .side .contactInfo div + div {
          margin-top: 30px; }
        #template-form #main-wrap .form-block .bottom .inner .side .contactInfo .image {
          display: block;
          margin-top: 25px; }

form input {
  font-family: "avenir-next-wf", sans-serif; }
  form input *:first-of-type {
    margin-top: 0; }
  form input[type=text], form input[type=email], form input[type=password], form input[type=textarea] {
    display: block;
    width: 100%;
    margin-top: 18px;
    border: solid 1px #888888;
    font-size: 15px;
    line-height: 1.5;
    padding: 10px 10px; }
  form input:disabled {
    cursor: not-allowed !important;
    opacity: .4; }

form textarea {
  font-family: "avenir-next-wf", sans-serif;
  display: block;
  width: 100%;
  height: 150px;
  margin-top: 18px;
  border: solid 1px #888888;
  font-size: 15px;
  line-height: 1.5;
  padding: 10px 10px; }

form .note {
  padding: 15px;
  margin-top: 18px;
  background-color: #888888; }
  form .note p {
    font-size: 14px;
    line-height: 1.5;
    color: #18428f;
    margin-top: 0; }

form input[type=submit] {
  font-family: "avenir-next-wf", sans-serif;
  text-transform: uppercase;
  letter-spacing: .1em;
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #f79f4b;
  font-size: 15px;
  font-weight: 700;
  color: white;
  padding: 10px 15px;
  line-height: 1.5; }
  form input[type=submit].link {
    background-color: transparent;
    display: inline;
    padding: 0;
    text-decoration: underline;
    color: #18428f;
    font-weight: 700; }
    form input[type=submit].link.smaller {
      font-size: 14px;
      font-weight: 400;
      text-decoration: none; }

.fieldRow {
  display: flex;
  justify-content: space-between; }
  .fieldRow:first-child input {
    margin-top: 0; }
  .fieldRow input {
    width: calc(50% - 10px) !important; }

.input-wrap {
  position: relative; }
  .input-wrap .button, .input-wrap button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }
    .input-wrap .button:disabled, .input-wrap button:disabled {
      cursor: not-allowed !important;
      opacity: .4; }

.thankyou {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  _transition-duration: 0s;
  font-size: .85em;
  margin: 0;
  margin-top: .5em; }
  .thankyou.show {
    opacity: 1;
    visibility: visible;
    transition-duration: .3s; }

::-webkit-input-placeholder {
  color: #888888; }

::placeholder {
  color: #888888; }

.submit-wrap {
  position: relative;
  text-align: right;
  clear: both;
  margin-top: 25px; }
  .submit-wrap .loader {
    position: relative;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    margin-right: 1em;
    vertical-align: middle;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s; }
    .submit-wrap .loader div {
      width: 100%;
      height: 100%; }
      .submit-wrap .loader div div {
        width: 100%;
        height: 100%; }
        .submit-wrap .loader div div svg {
          width: 100%;
          height: 100%; }
  .submit-wrap.loading .loader {
    opacity: .7;
    visibility: visible; }
  .submit-wrap.full {
    margin-top: 1em;
    position: relative; }
    .submit-wrap.full .loader {
      position: absolute;
      right: 1em;
      top: 0;
      bottom: 0;
      margin: auto; }
  .submit-wrap input {
    display: inline-block !important;
    float: none !important;
    margin: 0 !important;
    vertical-align: middle; }
    .submit-wrap input:active {
      top: 0 !important; }
  .submit-wrap.footer {
    position: relative;
    float: right; }
    .submit-wrap.footer .loader {
      position: absolute;
      top: 0;
      right: 5px;
      width: 1.2em;
      pointer-events: none; }
    .submit-wrap.footer input {
      position: absolute;
      top: 0;
      right: 0; }

.close-x {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 12px;
  height: 12px;
  opacity: 1;
  transition: opacity .3s; }
  .close-x:hover {
    _opacity: .7; }
  .close-x:before, .close-x:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: grey; }
  .close-x:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .close-x:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

#template-home {
  padding-top: 87px; }
  #template-home .videos {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 86px);
    padding-bottom: 100px; }
    #template-home .videos .item {
      position: relative;
      width: 50%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      #template-home .videos .item:nth-of-type(1) video {
        object-position: left; }
      #template-home .videos .item .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .5s; }
        #template-home .videos .item .video video {
          object-fit: cover; }
      #template-home .videos .item:hover .video {
        opacity: .1; }
      #template-home .videos .item > .text {
        padding-left: 125px;
        padding-right: 125px;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
        margin: 0; }
        @media (max-width: 1100px) {
          #template-home .videos .item > .text {
            padding-left: 100px;
            padding-right: 100px; } }
        @media (max-width: 768px) {
          #template-home .videos .item > .text {
            padding-left: 50px;
            padding-right: 50px; } }
        @media (max-width: 600px) {
          #template-home .videos .item > .text {
            padding-left: 30px;
            padding-right: 30px; } }
        #template-home .videos .item > .text h3 {
          position: relative;
          color: white;
          font-size: 21px;
          transition: -webkit-transform .3s;
          transition: transform .3s;
          transition: transform .3s, -webkit-transform .3s; }
          #template-home .videos .item > .text h3:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -18px;
            width: 20px;
            border-top: solid 1px white; }
        #template-home .videos .item > .text h1 {
          color: white;
          font-size: 42px;
          line-height: 1.2;
          font-weight: 700;
          margin-top: 40px;
          transition: -webkit-transform .3s;
          transition: transform .3s;
          transition: transform .3s, -webkit-transform .3s; }
        #template-home .videos .item > .text .links {
          transition: -webkit-transform .3s;
          transition: transform .3s;
          transition: transform .3s, -webkit-transform .3s; }
      #template-home .videos .item:hover > .text h3 {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px); }
      #template-home .videos .item:hover > .text h1 {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
  #template-home .text-centered {
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 120px;
    max-width: 1000px;
    margin: auto; }
    @media (max-width: 1100px) {
      #template-home .text-centered {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 768px) {
      #template-home .text-centered {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 600px) {
      #template-home .text-centered {
        padding-left: 30px;
        padding-right: 30px; } }
  #template-home .image-divider {
    position: relative; }
    #template-home .image-divider > .inner {
      padding-left: 125px;
      padding-right: 125px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 1; }
      @media (max-width: 1100px) {
        #template-home .image-divider > .inner {
          padding-left: 100px;
          padding-right: 100px; } }
      @media (max-width: 768px) {
        #template-home .image-divider > .inner {
          padding-left: 50px;
          padding-right: 50px; } }
      @media (max-width: 600px) {
        #template-home .image-divider > .inner {
          padding-left: 30px;
          padding-right: 30px; } }
      #template-home .image-divider > .inner img {
        margin: auto;
        width: 100%;
        max-width: 800px; }
    #template-home .image-divider .bg {
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      bottom: 0;
      background-color: #f1f4f8; }
  #template-home .image-text {
    background-color: #f1f4f8;
    padding-top: 100px;
    padding-bottom: 100px; }
    #template-home .image-text > .inner {
      padding-left: 125px;
      padding-right: 125px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      display: flex; }
      @media (max-width: 1100px) {
        #template-home .image-text > .inner {
          padding-left: 100px;
          padding-right: 100px; } }
      @media (max-width: 768px) {
        #template-home .image-text > .inner {
          padding-left: 50px;
          padding-right: 50px; } }
      @media (max-width: 600px) {
        #template-home .image-text > .inner {
          padding-left: 30px;
          padding-right: 30px; } }
      #template-home .image-text > .inner .image {
        width: 45%;
        flex-shrink: 0;
        margin-left: 150px; }

#template-employers #main-wrap .upper {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1100px) {
    #template-employers #main-wrap .upper {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-employers #main-wrap .upper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-employers #main-wrap .upper {
      padding-left: 30px;
      padding-right: 30px; } }
  #template-employers #main-wrap .upper .text-image {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex; }
    #template-employers #main-wrap .upper .text-image .text *:first-child {
      margin-top: 0; }
    #template-employers #main-wrap .upper .text-image .image {
      width: 45%;
      flex-shrink: 0;
      margin-left: 100px;
      margin-top: 5px; }

#template-employers #main-wrap .text-image-full {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1100px) {
    #template-employers #main-wrap .text-image-full {
      padding-left: 100px;
      padding-right: 100px; } }
  @media (max-width: 768px) {
    #template-employers #main-wrap .text-image-full {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 600px) {
    #template-employers #main-wrap .text-image-full {
      padding-left: 30px;
      padding-right: 30px; } }
  #template-employers #main-wrap .text-image-full > .inner {
    display: flex;
    border-top: solid 1px rgba(24, 66, 143, 0.35);
    padding: 60px 0; }
    #template-employers #main-wrap .text-image-full > .inner .image {
      position: relative;
      width: 33%;
      flex-shrink: 0; }
      @media (max-width: 1100px) {
        #template-employers #main-wrap .text-image-full > .inner .image img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; } }
    #template-employers #main-wrap .text-image-full > .inner > .text {
      padding-left: 60px; }
  #template-employers #main-wrap .text-image-full.reverse > .inner {
    flex-direction: row-reverse; }
    #template-employers #main-wrap .text-image-full.reverse > .inner > .text {
      padding-left: 0;
      padding-right: 60px; }

#template-employers #main-wrap .next-steps {
  margin-top: 40px; }

