#menu { position:fixed; z-index:10; top:0; left:0; width:100%; transition:transform .5s, background-color .5s, border-color .5s; border-bottom:solid 1px transparent;
  &.up { transform:translateY(-100%);
    ul.sub, ul.sub-2 { opacity:0 !important; visibility:hidden !important }
  }
  > .loaded-wrap { transition:transform .5s ease 1s;
    &.up { transform:translateY(-100%) }
    > .inner { display:flex; justify-content:space-between; align-items:flex-end; padding: 20px 40px; _background-color:white;
      
      .logo { width: 160px }
      ul { list-style:none; margin-top:0; padding-left:0;
        li { display:inline-block; margin-top:0;
          a { padding:10px 10px; display:block; color:$blue;
            > .inner { position:relative; 
              &::after { content:""; position:absolute; bottom:-8px; left:0; width:100%; border-bottom:solid 3px $orange; transform:scaleX(0); opacity:0; transition:transform .3s, opacity .3s }
            }
            &.active, &:hover {
              > .inner::after { transform:scaleX(.3); opacity:1 }
            } 
          }
        }
      }
    }
  }
}
#menu > .loaded-wrap > .inner > ul > li:last-of-type > a { padding-right:0 }
#menu {
  > .loaded-wrap {
    > .inner {
      > ul { display:flex; 
        > li { position:relative; margin:0 10px;
          ul.sub { position:absolute; z-index:1; display:block; top:40px; left:0; min-width:100%; background-color:white; border:solid 1px rgba(0,0,0,.2); @include fade-out;
            > li { position:relative; display:block; white-space:nowrap; 
              > a { transition:background-color .3s }
              &:hover > a { background-color:rgba(0,0,0,.05); }
              ul.sub-2 { position:absolute; display:block; top:-1px; left:100%; background-color:white; border:solid 1px rgba(0,0,0,.2); @include fade-out;
                > li { display:block; 
                  > a { transition:background-color .3s }
                  &:hover > a { background-color:rgba(0,0,0,.05) }
                }
              }
              &:hover { 
                ul.sub-2 { @include fade-in; }
              }
            }
          }
          &:hover { 
            ul.sub { @include fade-in }
          }
        }
      }
    }
  }

}
body.menu-has-bg #menu { background-color:white; border-bottom-color: $blue-15 }



// @include for-ipad-v {
//   #menu { height:60px; 
//     > .loaded-wrap { 
//       .thumb { position:absolute; z-index:1; top:10px; right:38px; display:flex; flex-direction:column; justify-content:space-between; width:50px; height:40px; padding:12px; cursor:pointer;
//         @include for-phone { right:18px }
//         > div { width:100%; height:2px; background-color:white; transition:background-color .5s, opacity .5s, transform .5s; }
//       }
//       > .inner { transition:transform .5s; padding:0;
//         transform:translateY(calc(-100% + 60px)); flex-direction:column-reverse; align-items:flex-start; padding:0;
//         .logo { width:133px; padding:10px 30px; margin-left:20px;
//           @include for-phone { margin-left:0 }
//         }
//         ul { padding:15px 0 7px;
//           li { display:block;
//             a { display:inline-block; padding:7px 50px; font-size:13px; 
//               @include for-phone { padding:7px 30px;  }
//               > .inner::after { bottom:-5px; border-bottom-width:2px; transform-origin:left }
//             }
//           }
//         }
//       }
//     }
//     &.open, &.open.up { transform:none;
//       > .loaded-wrap {
//         .thumb {
//           .one { transform:rotate(45deg) translate3d(5px, 5px, 0); }
//           .two { opacity:0 }
//           .three { transform:rotate(-45deg) translate3d(5px, -5px, 0); }
//         }
//         > .inner { transform:none; }
//       }
//     }
//   }
// }



